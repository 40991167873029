import {
  Edit,
  useLocaleState,
  useRecordContext,
  useTranslate,
  useNotify,
} from 'react-admin';
import LocationForm from '../../Common/Locations/Form';
import { EditProps } from 'ra-ui-materialui/src/types';

const EditTitle = () => {
  const record = useRecordContext();
  const [locale] = useLocaleState();
  const translate = useTranslate();
  const titleText = record?.translations?.name?.[locale] ?? undefined;

  return (
    <span>
      {translate('app.edit.locations.title')}{' '}
      {titleText ? `"${titleText}"` : ''}
    </span>
  );
};

const EditLocation = (props: EditProps) => {
  const notify = useNotify();

  const onSuccess = () => {
    notify('app.form.locations.create.success', {
      type: 'success',
      messageArgs: { smart_count: 1 },
    });
    window.location.href = '/admin/locations';
  };

  return (
    <Edit {...props} mutationOptions={{ onSuccess }} actions={false} title={<EditTitle />} mutationMode={'pessimistic'}>
      <LocationForm />
    </Edit>
  );
};

export default EditLocation;
